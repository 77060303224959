<template>
  <StaticPage>
    <el-card class="form_wrapper">
      <h2>{{ $t('changePw.header') }}</h2>
      <div id="force-changePassword">
        <div class="changePassword_box">
          <div class="change_form">
            <el-form
              label-position="top"
              :model="ruleChangePasswordForm"
              :rules="rulesChangePassword"
              ref="ruleChangePasswordForm"
            >
              <el-form-item :label="$t('changePw.field.newPw')" prop="pass">
                <el-input
                  type="password"
                  v-model="ruleChangePasswordForm.pass"
                  autocomplete="off"
                  data-testid="newPw"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('changePw.field.confirmPw')" prop="checkPass">
                <el-input
                  type="password"
                  v-model="ruleChangePasswordForm.checkPass"
                  autocomplete="off"
                  data-testid="confirmPw"
                ></el-input>
              </el-form-item>
              <VerificationCode
                ref="verificationCode"
                namespace="CP_FORCE_UPDATE_PASSWORD"
                :token="token"
                :maskedEmail="maskedEmail"
                :callbackRegulator="callbackRegulator"
              ></VerificationCode>
              <el-button class="w-100" type="primary" @click="submitForm()" data-testid="submit">
                {{ $t('menu.cpPassword') }}
              </el-button>
            </el-form>
          </div>
        </div>
      </div>
    </el-card>
    <PasswordRenewalReminderDialog
      v-if="token"
      :reminderDialogVisible.sync="reminderDialogVisible"
      @confirmSubmit="confirmSubmit"
    />
  </StaticPage>
</template>

<script>
import { md5 } from '@/util/encrypt';
import Result from '@/components/Result';
import StaticPage from '@/components/template/staticPage/StaticPageDefault';
import VerificationCode from '@/components/withdraw/VerificationCode';
import PasswordRenewalReminderDialog from '@/components/auth/PasswordRenewalReminderDialog.vue';
import { apiSendEmailVerificationCode, apiCheckPassword, apiForceUpdatePassword } from '@/resource';

export default {
  name: 'forceChangePassword',
  components: { StaticPage, Result, VerificationCode, PasswordRenewalReminderDialog },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*.()])(?=.*[0-9])(?=.*[a-z]).{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('common.formValidation.newPwFormat')));
        } else {
          callback();
        }
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleChangePasswordForm.pass) {
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      }
      callback();
    };
    return {
      formFlag: true,
      ruleChangePasswordForm: {
        pass: '',
        checkPass: ''
      },
      rulesChangePassword: {
        pass: [{ validator: validatePass, trigger: ['blur', 'change'] }],
        checkPass: [{ validator: validateCheckPass, trigger: ['blur', 'change'] }]
      },
      token: null,
      callbackRegulator: null,
      maskedEmail: null,
      reminderDialogVisible: false
    };
  },
  mounted() {
    this.token = this.$route.params.token;
    this.callbackRegulator = this.$route.params.callbackRegulator;
    this.maskedEmail = this.$route.params.maskedEmail;
    if (!this.token) this.goToLogin();
  },
  methods: {
    confirmSubmit() {
      this.forceUpdatePassword();
    },
    submitForm() {
      const [getPasswordForm, getVerifyCodeComponent] = [
        this.$refs.ruleChangePasswordForm,
        this.$refs.verificationCode
      ];
      const [verifyPasswordMethod, verifyCodeMethod] = [
        getPasswordForm.validate(),
        getVerifyCodeComponent.submitForm()
      ];

      Promise.all([verifyPasswordMethod, verifyCodeMethod]).then(valid => {
        if (valid) {
          this.reminderDialogVisible = true;
        } else return false;
      });
    },
    forceUpdatePassword() {
      const getVerifyCodeComponent = this.$refs.verificationCode;

      apiForceUpdatePassword({
        pass: md5(this.ruleChangePasswordForm.pass),
        checkPass: md5(this.ruleChangePasswordForm.checkPass),
        token: this.token,
        ...getVerifyCodeComponent.getFormDataOfChild()
      }).then(resp => {
        if (resp.data.code === 0 || resp.data.code === 530) this.goToLogin();
        if (resp.data.code === 647) {
          this.$message({
            message: this.$t('responseMsg.647'),
            type: 'error'
          });
        }
        this.reminderDialogVisible = false;
      }).catch(err => {
        console.log('err', err);
        this.reminderDialogVisible = false;
      });
    },
    goToLogin() {
      return this.$router.push('/login');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
.form_wrapper {
  padding: 0 !important;
}

/deep/ .el-form-item__error {
  position: relative;
}
</style>
